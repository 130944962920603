<template>
  <div class="bg-white">

    <div class="clearfix"></div>

    <Va404/>

    <div class="clearfix"></div>

  </div>
</template>

<script>

import Va404 from '@/components/Va404.vue'

export default {

  name: 'NotFound',

  components: {
    Va404
  }

}
</script>
